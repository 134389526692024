import React from "react";
import Genre from "../../components/genre/genre";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { contest } from "../../contest/contest";
import AlbumProvider from "../../context/AlbumProvider";

function Main(props) {
  return (
    <AlbumProvider>
      <div className="w-full md:w-3/5 mx-auto p-8 overflow-auto">
        <Header contest={contest} />
        {contest.genres.map(g => (
          <Genre genre={g} key={g.id} contest={contest} />
        ))}
        <Footer />
      </div>
    </AlbumProvider>
  );
}
export default Main;
