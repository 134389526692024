import React, { useContext } from "react";
import Uploader from "../uploader/uploader";
import Photo from "../photo/photo";
import AlbumContext from "../../context/albumContext";

const Genre = ({ genre, contest }) => {
  const { album, removePhoto, addPhoto } = useContext(AlbumContext);
  const photos = album
    .filter(p => p.genre === genre.id)
    .filter(p => p.contest === contest.id);
  console.log(`photos`, photos)

  return (
    <div className="tab w-full overflow-auto border-t">
      <input
        className="absolute opacity-0"
        id={`tab-multi-${genre.id}`}
        type="checkbox"
        name="tabs"
      />
      <label
        className="block p-5 leading-normal cursor-pointer"
        htmlFor={`tab-multi-${genre.id}`}
      >
        {genre.name}
      </label>
      <div className="tab-content overflow-auto border-l-2 bg-gray-100 border-blue-400 leading-normal">
        <div className="container my-12 mx-auto px-4 md:px-12">
          <div className="flex flex-wrap -mx-1 lg:-mx-4">
            {genre.limit > photos.length && (
              <Uploader genre={genre} contest={contest} addPhoto={addPhoto} />
            )}
          </div>
          <div className="flex flex-wrap -mx-1 lg:-mx-4 overflow-auto">
            {photos.map(photo => (
              <Photo
                photo={photo}
                contest={contest}
                key={photo.id}
                removePhoto={removePhoto}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Genre;
