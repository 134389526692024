import React, { useReducer, useContext } from "react";
import axios from "axios";
import AlbumContext from "./albumContext";
import albumReducer from "./albumReducer";
import { AuthContext } from "../auth/auth";

const AlbumProvider = props => {
  // const photos = [];
  // app
  //   .firestore()
  //   .collection("photos")
  //   .where("user_id", "==", currentUser.uid)
  //   .get()
  //   .then(snapshot => {
  //     if (snapshot.empty) {
  //       return;
  //     }
  //     snapshot.forEach(doc => {
  //       photos.push({
  //         id: doc.id,
  //         ...doc.data()
  //       });
  //     });
  //   })
  //   .catch(err => {});
  const { currentUser } = useContext(AuthContext);

  const initialState = {
    album: []
  };
  // Get all Photos
  const getPhotos = async () => {
    try {
      const token = await currentUser.getIdToken();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token
        }
      };
      const res = await axios.get(
        "https://us-central1-cctr-api.cloudfunctions.net/app/photos",
        config
      );
      const data = res.data;
      dispatch({ type: "SET_PHOTOS", payload: data });
    } catch (err) {
      // console.log(err);
    }
  };

  const removePhoto = async id => {
    try {
      dispatch({ type: "REMOVE_PHOTO", payload: id });
    } catch (err) {
      // console.log(err);
    }
  };

  const addPhoto = async data => {
    try {
      dispatch({ type: "ADD_PHOTO", payload: data });
    } catch (err) {
      // console.log(err);
    }
  };

  const [state, dispatch] = useReducer(albumReducer, initialState);

  return (
    <AlbumContext.Provider
      value={{
        album: state.album,
        getPhotos,
        addPhoto,
        removePhoto
      }}
    >
      {props.children}
    </AlbumContext.Provider>
  );
};

export default AlbumProvider;
