import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { AuthContext } from "../../auth/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 160,
  padding: 4,
  boxSizing: "border-box"
};

const thumbInner = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 100,
  overflow: "hidden"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const Uploader = ({ genre, contest, addPhoto }) => {
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const memberid = "000";
  // const [memberid, setMemberId] = useState("");

  const { currentUser } = useContext(AuthContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg",
    multiple: false,
    maxSize: 1048576 * contest.maxSize,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    onDropRejected: () => { 
      toast.error("File Error ! 图片格式或尺寸错误 （请选择不超过4M 的JPEG 文件） !", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });

  
  const uploadFiles = async () => {
    if (!title || files.length ===0) { 
      toast.error("Upload Error ! 请正确选择图片并且输入标题 !", {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    }
    // if (!memberid ) { 
    //   toast.error("Missing Membership ID ! 请正确输入会员号 !", {
    //     position: toast.POSITION.TOP_CENTER
    //   });

    //   return false;
    // }
    let formData = new FormData();
    const token = await currentUser.getIdToken();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token
      }
    };


    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append("articleFiles[]", file);
    }

    formData.append("contest", contest.id);
    formData.append("genre", genre.id);
    formData.append("title", title);
    formData.append("memberid", memberid);

    axios
      .post(
        "https://us-central1-cctr-api.cloudfunctions.net/app/photo",
        formData,
        config
      )
      .then(res => {
        setFiles([]);
        setTitle("");
        toast.success("Upload Successful 图片上传成功 !", {
          position: toast.POSITION.TOP_CENTER
        });
        addPhoto(res.data.data);
      })
      .catch(error => {
        setFiles([]);
        setTitle("");
        toast.error("Upload Error ! 图片上传错误 !", {
          position: toast.POSITION.TOP_CENTER
        });
      });
  };

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="preview" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );


  return (
    <div className="my-1 px-1 w-full md:w-full lg:my-4 lg:px-4 lg:w-full">
      <div className="overflow-hidden rounded-lg shadow-lg">
        <div className="container border border-dashed border-gray-400 relative min-h-full">
          <ToastContainer />
          <div
            {...getRootProps({
              className: "drop-zone"
            })}
          >
            <input {...getInputProps()} />
            <div className="text-center p-10 absolute top-0 right-0 left-0 m-auto">
              <h4>
                Drop files here to upload
                <br />
                <span className="chinese-font">将文件拖放至此</span>
              </h4>
            </div>
            <div style={thumbsContainer}>{thumbs}</div>
          </div>
        </div>
        <div className="flex items-center flex-col leading-tight p-2 md:p-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 chinese-font"
            htmlFor="photo-title"
          >
            作品名称 (请只使用英文)
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id={`title-${genre.id}`}
            placeholder="image title"
            value={title}
            type="text"
            name="title"
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
          {/* <label
            className="block text-gray-700 text-sm font-bold mb-2 chinese-font"
            htmlFor="memberid"
          >
            会员号
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id={`memberid-${genre.id}`}
            placeholder="0123"
            value={memberid}
            type="text"
            name="memberid"
            onChange={e => {
              setMemberId(e.target.value);
            }}
          /> */}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline px-24"
            type="button"
            onClick={uploadFiles}
          >
            Upload <span className="chinese-font">上传</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Uploader;
