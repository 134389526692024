import React, { useCallback, useState } from "react";
import { withRouter } from "react-router";
import app from "../../firebase/firebase";

function Password({ history }) {
  const [message, setMessage] = useState("");
  const handleResetPassword = useCallback(
    async event => {
      event.preventDefault();
      const { email } = event.target.elements;

      try {
        await app
          .auth()
          .sendPasswordResetEmail(email.value)
          .then(() => {
            // Email sent.
            history.push("/");
          })
          .catch(error => {
            setMessage(error.message);
          });
      } catch (error) {
        setMessage(error.message);
      }
    },
    [history]
  );
  return (
    <div className="container mx-auto">
      <div className="flex justify-center px-6 my-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          <div
            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
            style={{
              backgroundImage: "url('/images/bg-02.jpg')"
            }}
          ></div>
          <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
            <div className="px-8 mb-4 text-center">
              <h3 className="pt-4 mb-2 text-2xl">
                Forgot Your Password? <br />
                <span className="chinese-font">忘记密码了吗?</span>
              </h3>
              <p className="mb-4 text-sm text-gray-700">
                We get it, stuff happens. Just enter your email address below
                and we'll send you a link to reset your password!
              </p>
              <p className="mb-4 text-sm text-gray-700 chinese-font">
                输入您的邮箱地址.我们将向您发送一个链接以重置密码
              </p>
              <p className="mb-4 text-sm text-red-700">{message}</p>
            </div>
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
              onSubmit={handleResetPassword}
            >
              <div className="mb-4">
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email <span className="chinese-font">电子邮件</span>
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter Email Address..."
                />
              </div>
              <div className="mb-6 text-center">
                <button
                  className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded-full hover:bg-red-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset Password <span className="chinese-font">重置密码</span>
                </button>
              </div>
              <hr className="mb-6 border-t" />
              <div className="text-center">
                <a
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="./register"
                >
                  Create account{" "}
                  <span className="chinese-font">创建新账户</span>
                </a>
              </div>
              <div className="text-center">
                <a
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="./login"
                >
                  Already have an account? Login{" "}
                  <span className="chinese-font">已有帐号了？ 请登录</span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Password);
