import React, { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../auth/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Photo({ photo, contest, removePhoto }) {
  const { currentUser } = useContext(AuthContext);

  const deleteFile = async imageId => {
    const token = await currentUser.getIdToken();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token
      }
    };
    axios
      .delete(
        `https://us-central1-cctr-api.cloudfunctions.net/app/photo/${imageId}`,
        config
      )
      .then(() => {
        toast.success("Delete Successfully 图片删除成功 !", {
          position: toast.POSITION.TOP_CENTER
        });
        removePhoto(imageId);
      })
      .catch(error => {
        toast.error("Error ! 图片删除错误 !", {
          position: toast.POSITION.TOP_CENTER
        });
      });
  };
  return (
    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
      <ToastContainer />
      <article className="overflow-hidden rounded-lg shadow-lg">
        <img
          alt="Placeholder"
          className="block h-auto w-full"
          src={`https://firebasestorage.googleapis.com/v0/b/cctr-api.appspot.com/o/cctr%2F${contest.id}%2F${photo.genre}%2F${photo.image_name}?alt=media`}
        />

        <header className="flex items-center justify-between leading-tight p-2 md:p-4">
          <h1 className="text-lg">{photo.title}</h1>
        </header>

        <footer className="flex float-left items-center justify-between leading-none p-2 md:p-4 text-red-200 hover:text-red-500 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-trash-2"
            onClick={() => {
              deleteFile(photo.id);
            }}
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
          <p
            className="ml-2 text-sm"
            onClick={() => {
              deleteFile(photo.id);
            }}
          >
            remove
          </p>
        </footer>
      </article>
    </div>
  );
}

export default Photo;
