export default (state, action) => {
  switch (action.type) {
    case "SET_PHOTOS":
      return {
        ...state,
        album: action.payload
      };
    case "REMOVE_PHOTO":
      return {
        ...state,
        album: state.album.filter(p => p.id !== action.payload)
      };
    case "ADD_PHOTO":
      return {
        ...state,
        album: [...state.album, action.payload]
      };
    default:
      return state;
  }
};
