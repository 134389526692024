import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import app from "../../firebase/firebase";

const Register = ({ history }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = useCallback(
    data => {
      const {
        email,
        password,
        displayName
        // ,
        // realName,
        // clubName,
        // memberId
      } = data;
      const createdAt = new Date();
      try {
        app
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(userData => {
            app
              .firestore()
              .collection("users")
              .doc(userData.user.uid)
              .set({
                displayName,
                // realName,
                // clubName,
                // memberId,
                email,
                createdAt
              });
              app.auth().currentUser.updateProfile({
                displayName
              })
          })
          .then(() => {
            history.push("/");
          })
          .catch(error => {
            // console.log(error.message);
          });
      } catch (error) {
        // console.log(error.message);
      }
    },
    [history]
  );
  return (
    <div className="container mx-auto">
      <div className="flex justify-center px-6 my-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          <div
            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
            style={{
              backgroundImage: "url('/images/bg-03.jpg')"
            }}
          ></div>
          <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
            <h3 className="pt-4 text-2xl text-center">Create an Account</h3>
            <h3 className="pt-4 text-2xl text-center chinese-font">
              创建新账户
            </h3>

            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-4">

                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="displayName"
                  >
                    Display Name <span className="chinese-font">姓名</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="displayName"
                    name="displayName"
                    type="text"
                    placeholder="Display Name"
                    ref={register({
                      required: "Required",
                      pattern: {
                        value: /^[A-Za-z\d\-_\s]+$/i,
                        message: "English name Only"
                      }
                    })}
                  />
                  <p className="text-xs text-red-300">
                    {errors.displayName && errors.displayName.message}
                  </p>

                  {/* <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="realName"
                  >
                    Real Name<span className="chinese-font">真实姓名</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="realName"
                    name="realName"
                    type="text"
                    placeholder="Real Name"
                    ref={register({ required: true, maxLength: 30 })}
                  />
                  <p className="text-xs text-red-300">
                    {errors.realName && "Required"}
                  </p> */}

              </div>
              {/* <div className="mb-4 md:flex md:justify-between">
                <div className="mb-4 md:mr-2 md:mb-0">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="clubName"
                  >
                    Club Name <span className="chinese-font">俱乐部名称</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="clubName"
                    name="clubName"
                    type="text"
                    placeholder="Club Name in English"
                    ref={register({ pattern: /^[A-Za-z]+$/i })}
                  />
                  <p className="text-xs text-red-300">
                    {errors.clubName && "English name ONLY"}
                  </p>
                </div>
                <div className="md:ml-2">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="memberId"
                  >
                    Club Member Id<span className="chinese-font">会员编号</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="memberId"
                    name="memberId"
                    type="text"
                    placeholder="Member ID"
                    ref={register({ min: 1, max: 999 })}
                  />
                  <p className="text-xs text-red-300">
                    {errors.memberId && "Numbers ONLY"}
                  </p>
                </div>
              </div>
              */}
              <div className="mb-4"> 
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email <span className="chinese-font">电子邮件</span>
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  ref={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address"
                    }
                  })}
                />
                <p className="text-xs text-red-300">
                  {errors.email && errors.email.message}
                </p>
              </div>
              <div className="mb-4">
              {/* <div className="mb-4 md:flex md:justify-between"> */}
                {/* <div className="mb-4 md:mr-2 md:mb-0"> */}
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="password"
                  >
                    Password <span className="chinese-font">登录密码</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="password"
                    name="password"
                    type="password"
                    placeholder=""
                    ref={register({
                      required: true,
                      minLength: 8,
                      maxLength: 30
                    })}
                  />
                  <p className="text-xs text-red-300">
                    {errors.password &&
                      "Password needs to be at least 8 characters. "}
                  </p>

                {/* </div><div className="md:ml-2">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="c_password"
                  >
                    Confirm <span className="chinese-font">确认密码</span>
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="c_password"
                    name="c_password"
                    type="password"
                    placeholder=""
                  />
                </div> */}
              </div>
              <div className="mb-6 text-center">
                <button
                  className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Register Account{" "}
                  <span className="chinese-font">注册账户</span>
                </button>
              </div>
              <hr className="mb-6 border-t" />
              <div className="text-center">
                <a
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="/password"
                >
                  Forgot Password?{" "}
                  <span className="chinese-font">忘记密码</span>
                </a>
              </div>
              <div className="text-center">
                <a
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="./login"
                >
                  Already have an account? Login{" "}
                  <span className="chinese-font">已有帐号了？ 请登录</span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Register);
