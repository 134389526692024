import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./auth/auth";
import PrivateRoute from "./routes/private-route";

import Login from "./pages/login/login";
import Password from "./pages/password/password";
import Register from "./pages/register/register";
import Home from "./pages/main/main";

import "./styles/app.css";

function App() {
  return (
    <AuthProvider>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/password" component={Password} />
          <Route exact path="/register" component={Register} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
