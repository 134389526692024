import React from "react";


const Footer = () => {

  return (
    <div className="flex w-full text-blue-500 self-end py-2 px-4 mt-6 content-end">
      
    </div>
  );
};

export default Footer;
