export const contest = {
  name: "2024大西洋摄协会员春季内赛",
  id: "APA2024SP",
  maxSize: 4,
  genres: [
    {
      id: "AR",
      name: "艺术",
      limit: 2
    },
    {
      id: "NA",
      name: "自然",
      limit: 2
    }
  ]
};
