import React, { useEffect, useContext } from "react";
import AlbumContext from "../../context/albumContext";
import app from "../../firebase/firebase";
import { AuthContext } from "../../auth/auth";

const Header = ({ contest }) => {
  const { album, getPhotos } = useContext(AlbumContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const genres = [...new Set(album.map(a => a.genre))];

  const activeGenres = contest.genres.map(genre => genre.id)
  
  const filteredAlbum = album.filter(a => a.contest === contest.id);
  const filteredGenres = genres.filter(g => activeGenres.includes(g));

  return (
    <>
      <div className="flex w-full text-blue-500 self-end py-2 px-1 mt-6 content-end">
        <div className="text-xs lg:flex-grow my-5 xl:mx-1">
          {
            currentUser.displayName && 
                    <p className="block lg:inline-block text-gray-600 font-bold">
                    当前用户 / You are login as: <span className="text-lg uppercase pl-2">{ currentUser.displayName}</span>
                    </p>
          }  

      </div>
      <div className="text-sm my-5 xl:mx-8 flex-end">
        <div><button onClick={() => app.auth().signOut()}>登出 / LOGOUT</button>
      </div>
      </div>
    </div>
    <div className="w-full flex-grow lg:flex items-center lg:w-auto hidden bg-blue-500 ">
      <div className="text-sm lg:flex-grow my-5 animated jackinthebox xl:mx-8">
        <p className="block lg:inline-block text-white text-md font-bold uppercase">
          {contest.name}
        </p>
      </div>
      <div className="text-sm my-5 animated jackinthebox xl:mx-8 flex-end">
        <p className="block lg:inline-block text-white text-md lowercase ">
          your submission: categories: {filteredGenres.length} / {contest.genres.length}
          , total images: {filteredAlbum.length} /{" "}
          {contest.genres.reduce((a, g) => a + g.limit, 0)}
        </p>
      </div>
      </div>
    </>
  );
};

export default Header;
